<template>
  <div>
    <div class="" style="width: 100%">
      <img style="display: block;width: 1920px;margin: auto;" src="../../assets/images/djt/yaq.jpg" alt="">
    </div>
    <div class="bg">
      <div class="wrapper">
        <div>
          <div _ngcontent-c6="" class="page1">
            <h2 _ngcontent-c2="" class="center">云安全</h2>
            <p _ngcontent-c2="" class="em2">关于云安全：“云安全（Cloud Security）”技术是网络时代信息安全的最新体现，它融合了并行处理、网格计算、未知病毒行为判断等新兴技术和概念，通过网状的大量客户端对网络中软件行为的异常监测，获取互联网中木马、恶意程序的最新信息，推送到Server端进行自动分析和处理，再把病毒和木马的解决方案分发到每一个客户端。</p>
            <p _ngcontent-c2="" class="em2 bold">云安全应对：</p>
            <ul _ngcontent-c2="" class="yun-list">
              <li _ngcontent-c2="">
                <img _ngcontent-c2="" alt="" src="../../assets/images/djt/y1.png">
                <p _ngcontent-c2="">漏洞扫描与渗透测试</p>
              </li>
              <li _ngcontent-c2="">
                <img _ngcontent-c2="" alt="" src="../../assets/images/djt/y2.png">
                <p _ngcontent-c2="">云安全技术配置管理</p>
              </li>
              <li _ngcontent-c2="">
                <img _ngcontent-c2="" alt="" src="../../assets/images/djt/y3.png">
                <p _ngcontent-c2="">云安全技术的安全控制</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>

<style lang="less" scoped>
.yun-list[_ngcontent-c2]{
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.yun-list[_ngcontent-c2]   li[_ngcontent-c2]{
  text-align: center;
}
.yun-list[_ngcontent-c2]   li[_ngcontent-c2]   img[_ngcontent-c2]{
  width: 100px;
}
  .bold {
    font-weight: bold;
  }
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2{
  text-indent: 2em;
  line-height: 40px;
}
.center{
  text-align: center;
}
.img {
  text-align: center;
  img {
    width: 100%;
  }
  // width: 100%;
  // display: flex;
  // padding: 10px 0;
  // align-items: center;
  // justify-content: center;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
